<template>
  <div class="bigDiv">
    <img src="../../assets/images/map/1.jpg" alt="" />
    <img src="../../assets/images/map/2.jpg" alt="" />
    <img src="../../assets/images/map/3.jpg" alt="" />
    <img src="../../assets/images/map/btoImg.gif" alt="" class="btoImg" @click="FxShow = true" />
    <img src="../../assets/images/fx.png" alt="" class="topImg" v-if="FxShow" />
    <div class="modio" ref="mode" v-if="FxShow" @click="FxShow = false" :style="{ height: innerHeight + 'px', width: innerWidth + 'px' }"></div>
  </div>
</template>

<script>
import { getWechatConfig } from "@/api/yangsheng";

export default {
  name: "",
  props: {},
  components: {},
  data () {
    return {
      innerWidth: "",
      FxShow: false,
      innerHeight: "",
    };
  },
  methods: {
    getWechatConfig () {
      const query = {
        company_id: 1,
        url: window.location.href,
      };
      getWechatConfig(query).then((res) => {
        const agent = res.data.data;
        if (res.data.code === 200) {
          this.$nextTick(() => { });
          wx.config({
            debug: agent.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: agent.appId, // 必填，公众号的唯一标识
            timestamp: agent.timestamp, // 必填，生成签名的时间戳
            nonceStr: agent.nonceStr, // 必填，生成签名的随机串
            signature: agent.signature, // 必填，签名
            //1.4.0的分享接口,注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
            //     "onMenuShareAppMessage", //老版本分享接口。
            //     "onMenuShareTimeline" //老版本分享接口。
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData,'onMenuShareAppMessage",
              "onMenuShareTimeline",
            ], // 必填，需要使用的JS接口列表
          });

          this.share();
        }
      });
    },
    share () {
      this.$nextTick(() => {
        wx.ready(function () {
          // 分享给朋友
          wx.updateAppMessageShareData({
            title: "养肝护肝指南", // 分享标题
            link: "https://kf.hshwhkj.com/liver", //这里可带参数，必须是同配置的JS安全域名一致
            desc: "养肝护肝指南", // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { },
          });
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: "养肝护肝指南", // 分享标题
            link: "https://kf.hshwhkj.com/liver", //这里可带参数，必须是同配置的JS安全域名一致
            desc: "养肝护肝指南", // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { },
          });
        });
        wx.error((res) => {
          console.log("微信分享错误信息：", res);
        });
      });
    },
  },
  mounted () {
    this.getWechatConfig();
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    console.log(this.innerHeight, this.innerWidth);
    if (this.innerWidth > 750) {
      this.innerWidth = 750;
    }
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang="less">
.bigDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
  }
  .btoImg {
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .topImg {
    width: 240px;
    height: 240px;
    object-fit: contain;
    position: fixed;
    top: -10px;
    right: 10px;
    z-index: 9;
  }
  .modio {
    width: 100%;
    min-height: 667px;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    img {
      width: 100%;
    }
    .btoImg {
      position: fixed;
      width: 750px;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
    }
    .topImg {
      width: 240px;
      height: 240px;
      object-fit: contain;
      position: fixed;
      top: 0;
      right: 30%;
    }
  }
}
</style>
